import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/possession-requests';

export default {
  listPossessionRequests(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  get(id) {
    return axios.get(`${baseUrl}/${id}`);
  },
  approve(id) {
    return axios.post(`${baseUrl}/${id}/approve`)
  },
  reject(id) {
    return axios.post(`${baseUrl}/${id}/reject`)
  },
};
