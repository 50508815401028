<template>
  <div class="email-app-details zindex-4">

    <!-- Task Header -->
    <div class="email-detail-header">
      <!-- Header: Left -->
      <div class="email-header-left d-flex align-items-center">
        <span class="go-back mr-1">
          <feather-icon
            icon="ChevronLeftIcon"
            size="20"
            class="align-bottom"
            @click="$emit('close-task-view')"
          />
        </span>
        <h4 v-if="taskViewData" class="email-subject mb-0">
          {{ taskViewData.title }}
        </h4>
        <h4 v-else class="email-subject mb-0">
          No Task Title
        </h4>
      </div>
      <div v-if="taskViewData" class="email-header-right ml-2 pl-1">

        <div class="email-label">
          <b-badge
            pill
            class="text-capitalize mr-50"
            variant="primary"
          >
            {{ taskViewData.category }}
          </b-badge>
          <b-badge
            v-if="taskViewData.type"
            pill
            class="text-capitalize"
            variant="danger"
          >
            {{ taskViewData.type }}
          </b-badge>
        </div>
      </div>
    </div>
    <!-- App Action Bar -->
    <div v-if="taskViewData" class="email-app-list" style="height: auto">
      <div class="app-action py-1 px-2">
        <div class="action-left container">
          <div class="row justify-content-between">
            <!-- Status -->
            <div class="col-10">
              <div class="row justify-content-between">
                <div class="col d-flex my-auto">
                  Status:
                  <span
                    class="mx-50 bullet bullet-sm my-auto"
                    :class="`bullet-${resolveStatusColor(taskViewData.status)}`"
                  />
                  <p class="font-weight-bolder mb-0">
                    {{ taskViewData.status }}
                  </p>
                </div>

                <!-- Assigned User -->
                <div class="col d-flex my-auto">
                  <div v-if="taskViewData.user">
                    Assigned: <strong>{{ taskViewData.user.name }}</strong>
                  </div>
                  <div v-else>
                    Assigned: <strong>Unassigned</strong>
                  </div>
                </div>

                <div class="w-100" />

                <!-- Created Date -->
                <div class="col m-0 d-flex my-auto">
                  <p v-if="taskViewData.created_at" class="m-0"> Created on: <strong>{{ taskViewData.created_at | formatDate }}</strong></p>
                </div>

                <!-- Due Date -->
                <div class="col m-0 d-flex my-auto">
                  <p v-if="taskViewData.due_date" class="m-0">Due: <strong>{{ taskViewData.due_date | formatDate }}</strong></p>
                  <p v-else class="m-0">Due: Not Set</p>
                </div>

              </div>
            </div>
            <!-- Update Button -->
            <div v-if="$can('Update', 'Tracker.'+taskViewData.category)" class="col-2 m-0 justify-content-end  d-flex my-auto">
              <b-button
                variant="primary"
                @click="updateTaskModal.show()"
              >
                Update
              </b-button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Task Details -->
    <vue-perfect-scrollbar
      ref="scrollbar"
      :settings="perfectScrollbarSettings"
      class="email-scroll-area scroll-area pb-5"
    >
      <!-- Task Summary -->
      <b-card v-if="taskViewData" class="mt-2">
        <section class="row">
          <div class="col-12">
            <strong>Description</strong>
            <div>
              <!-- eslint-disable vue/no-v-html -->
              <span class="m-0 d-block" style="white-space: pre-wrap;" v-html="taskViewData.description" />
              <!--eslint-enable-->
            </div>
            <div v-if="taskViewData.meta" class="row mt-2">
              <table class="table table-striped">
                <tbody v-for="(item, key) in taskViewData.meta" :key="key">
                  <tr>
                    <td class="pr-1">{{ key }}</td>
                    <td>{{ item }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </b-card>

      <!-- Task Contents -->
      <section v-if="taskViewData">
        <Order v-if="order != null" :order-data="order" @order-updated="getTaskDetails" />
        <issue-card v-if="issue != null" :issue-data="issue" />
        <view-task-messages v-if="$can('Read', 'TrackerMessage')" :task-id="taskViewData.id" />
        <possession-request v-if="possessionRequest != null" :request="possessionRequest" @updated="getTaskDetails"/>
        <model-activities :uid="taskViewData.id" model="task" :page-size="5" />
      </section>

      <b-card v-if="!taskViewData" title="Could not find task" class="mt-2" />
    </vue-perfect-scrollbar>

    <b-modal v-if="taskViewData" ref="updateTaskModal" title="Update task" hide-footer>
      <updateTaskDetails :task-details="taskViewData" @close="closeModal" @updated="taskUpdated" />
    </b-modal>
  </div>
</template>

<script>
import ModelActivities from '@/views/activities/ModelActivities.vue';
import Order from '@/views/tracker/Order.vue';
import TaskService from '@/services/TaskService';
import IssuesService from '@/services/IssuesService';
import helperService from '@/services/HelperService';
import { useToast } from 'vue-toastification/composition';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import OrderService from '@/services/OrderService';
import ViewTaskMessages from '@/views/tracker/viewTaskMessages.vue';
import { onMounted, ref, watch } from '@vue/composition-api';
import UpdateTaskDetails from '@/views/tracker/sections/updateTaskDetails.vue';
import IssueCard from '@/views/tracker/IssueCard.vue';

import useTask from './useTask'
import PossessionRequestsService from "@/services/PossessionRequestsService";
import PossessionRequest from "@/views/tracker/PossessionRequest.vue";

export default {
  components: {
    PossessionRequest,
    ModelActivities,
    VuePerfectScrollbar,
    Order,
    ViewTaskMessages,
    UpdateTaskDetails,
    IssueCard,
  },
  props: {
    taskId: {
      type: [Number, String],
      required: true,
    },
  },
  setup(props) {
    const { resolveStatusColor } = useTask()
    const toast = useToast()
    const order = ref(null)
    const scrollbar = ref(null)
    const updateTaskModal = ref(null)
    const taskViewData = ref(null)
    const issue = ref(null)
    const possessionRequest = ref(null)

    const scrollToTop = () => {
      // Scroll back to top of page when task changes
      scrollbar.value.$el.scrollTop = 0
    }

    const closeModal = () => {
      updateTaskModal.value.hide()
    }

    // Get Order details
    const getOrderData = async () => {
      try {
        const res = await OrderService.get(taskViewData.value.model_uid)
        order.value = res.data
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get order, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        order.value = null
        helperService.showNotfyErr(toast, err, errorText)
      }
    }
    // Get issue data
    const getIssueData = async () => {
      try {
        const { data } = await IssuesService.getIssue(taskViewData.value.model_uid)
        issue.value = data
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get issue, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        issue.value = null
        helperService.showNotfyErr(toast, err, errorText)
      }
    }
    const getPossessionRequestData = async () => {
      try {
        const res = await PossessionRequestsService.get(taskViewData.value.model_uid)
        possessionRequest.value = res.data
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get possessions, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        helperService.showNotfyErr(toast, err, errorText)
      }
    }
    // Get Task details
    const getTaskDetails = async () => {
      try {
        order.value = null
        possessionRequest.value = null
        const res = await TaskService.getTask(props.taskId)
        taskViewData.value = res.data

        // get order if task type is 'Shopping'
        if (taskViewData.value.category === 'Shopping') {
          await getOrderData()
        }
        // get issue if task type is issue
        if (taskViewData.value.category === 'Issue' && taskViewData.value.model === 'issues') {
          await getIssueData()
        }
        // get possessions if task type is store
        if (taskViewData.value.category === 'Store') {
          await getPossessionRequestData()
        }
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get task, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        helperService.showNotfyErr(toast, err, errorText)
      }
    }

    onMounted(() => {
      getTaskDetails()
    })

    // when taskId prop changes, get new task details and scroll to top of page
    watch(() => props.taskId, async () => {
      scrollToTop()
      await getTaskDetails()
    });

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Task Updated method
    const taskUpdated = () => {
      closeModal()
      getTaskDetails()
    }
    return {

      // UI
      perfectScrollbarSettings,
      order,
      // useTask
      resolveStatusColor,
      scrollToTop,
      scrollbar,
      updateTaskModal,
      closeModal,
      taskViewData,
      taskUpdated,
      getTaskDetails,
      issue,
      IssueCard,
      possessionRequest,
    }
  },
}
</script>

<style>
.inline {
  margin: 0;
  padding: 0;
  display: inline-block;
}
</style>
