<template>
  <div>
    <div class="p-0 d-flex justify-content-between mb-2">
      <b-card-title class="my-auto m-0">Possession Request</b-card-title>
      <div v-if="$can('Update', 'PossessionRequests') && request.status === 'Requested'" class="form-group-compose compose-btn">
        <b-button
          variant="primary"
          @click="showApproveModal"
        >
          Approve
        </b-button>
        <b-button
          class="ml-1"
          variant="danger"
          @click="showRejectModal"
        >
          Reject
        </b-button>
      </div>
    </div>

    <div>
      <b-card
        title="Details"
        sub-title="This table shows the general details for the request"
      >
        <div class="row mt-3">
          <div class="col-md-6">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td class="pr-1">Owner</td>
                  <td>
                    <router-link v-if="$can('Read', 'User')" :to="`/owners/owner/${request.owner.uid}`">
                      {{ request.owner.identifier }}
                    </router-link>
                    <span v-else>{{ request.owner.identifier }}</span>
                  </td>
                </tr>
                <tr>
                  <td class="pr1">Status</td>
                  <td>{{ request.status }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6">
            <table class="table table-striped">
              <tbody>
                <tr>
                  <td class="pr-1">Desired Location</td>
                  <td>{{ request.desired_location.name }}</td>
                </tr>
                <tr>
                  <td class="pr-1">Volumetric Control Enabled</td>
                  <td>{{ request.desired_location.apply_volumetric_controls }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-card>
    </div>
    <div>
      <b-card
        title="Volumetric Control"
        sub-title="This table shows the number of items the resident has in the desired location."
      >
        <VolumetricControl
          :location-id="request.desired_location_id"
          :owner-uid="request.owner_uid"
        />
      </b-card>
    </div>

    <div>
      <b-card
        title="Possessions"
        sub-title="This table show all requested possessions."
      >
        <b-table
          :fields="headers"
          :items="request.possessions"
          class="data-table"
          hover
        >
          <template #cell(title)="data">
            <router-link :to="{name: 'possession-details', params: {id: data.item.id}}">{{ data.item.title }}
            </router-link>
          </template>
          <template #cell(is_prohibited)="data">
            <span>{{ data.item.is_prohibited ? 'Yes' : 'No' }}</span>
          </template>
          <template #cell(is_risk)="data">
            <span>{{ data.item.is_risk ? 'Yes' : 'No' }}</span>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
import VolumetricControl from "@/views/possessions/sections/VolumetricControl.vue";
import PossessionRequestsService from "@/services/PossessionRequestsService";

export default {
  components: {VolumetricControl, },
  props: {
    request: {
      type: Object,
      required: true,
    },
  },
  emits: ['updated'],
  data() {
    return {
      headers: [
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: "category.name",
          label: "Category",
        },
        {
          key: "subcategory.name",
          label: "Subcategory",
        },
        {
          key: 'location.name',
          label: 'Location',
        },
        {
          key: 'is_prohibited',
          label: 'Prohibited',
        },
        {
          key: 'is_risk',
          label: 'Risk',
        },
      ],
    }
  },
  mounted() {
  },
  methods: {
    showApproveModal() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to approve this request? All possessions asked for in the request will be updated to the desired location.', {
        title: 'Approve Request',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'Approve',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          this.approve()
        }
      })
    },
    async approve() {
      try {
        await PossessionRequestsService.approve(this.request.id)
        this.$emit('updated');
      } catch(err) {
        const res = err.response
        let errorText = 'Could not approve request, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    showRejectModal() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to reject this request? This action cannot be undone.', {
        title: 'Reject Request',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Delete',
        cancelTitle: 'Cancel',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if (value) {
          this.reject()
        }
      })
    },
    async reject() {
      try {
        await PossessionRequestsService.reject(this.request.id)
        this.$emit('updated')
      } catch(err) {
        const res = err.response
        let errorText = 'Could not reject request, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    }
  }
}
</script>
