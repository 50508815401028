<template>
  <div v-if="taskDetails">
    <section>
      <label>Status</label>
      <b-form-select v-model="updateDetails.status" class="mb-2">
        <b-form-select-option value="Open">Open</b-form-select-option>
        <b-form-select-option value="Ongoing">Ongoing</b-form-select-option>
        <b-form-select-option value="Closed">Closed</b-form-select-option>
      </b-form-select>
    </section>

    <section>
      <label>Assign User</label>
      <b-form-select v-model="updateDetails.user_uid" class="mb-2">
        <b-form-select-option value="">Unassigned</b-form-select-option>
        <b-form-select-option v-for="user in users" :key="user.uid" :value="user.uid">{{ user.name }}</b-form-select-option>
      </b-form-select>
    </section>

    <section class="mb-2">
      <label for="example-datepicker">Due Date</label>
      <b-form-datepicker id="example-datepicker" v-model="updateDetails.due_date" />
    </section>

    <section class="d-inline-flex full-width mt-1">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button variant="primary" @click="updateTask">Update task</b-button>
    </section>
  </div>
</template>

<script>
import { formatDate } from '@core/utils/filter'
import { onMounted, ref } from '@vue/composition-api';
import helperService from '@/services/HelperService';
import { useToast } from 'vue-toastification/composition';
import TaskService from '@/services/TaskService';
import TaskUserService from '@/services/TaskUserService';

export default {
  components: {},
  props: {
    taskDetails: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const toast = useToast()

    const updateDetails = ref({
      status: props.taskDetails.status,
      due_date: props.taskDetails.due_date,
      user_uid: props.taskDetails.user_uid,
    })

    const users = ref(null)
    const getTaskUsers = async () => {
      try {
        const res = await TaskUserService.listAll(props.taskDetails.id)
        users.value = res.data
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get users with permission, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        helperService.showNotfyErr(toast, err, errorText)
      }
    }

    // Update task details
    const updateTask = async () => {
      try {
        const taskId = props.taskDetails.id

        // convert due date to iso format
        if (updateDetails.value.due_date !== null) {
          const date = new Date(updateDetails.value.due_date)
          updateDetails.value.due_date = date.toISOString()
        }

        await TaskService.updateTask(taskId, updateDetails.value)
        helperService.showNotfySuccess(toast, 'Task successfully updated')

        // Close modal and update task details page
        emit('updated')
      } catch (err) {
        const res = err.response
        let errorText = 'Could not update task, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        helperService.showNotfyErr(toast, err, errorText)
      }
    }

    onMounted(() => {
      getTaskUsers()
    })

    return {
      formatDate,
      users,
      updateDetails,
      updateTask,
    }
  },
}
</script>

<style>

</style>
