<template>
  <b-card no-body>
    <!-- User Data -->
    <b-card-header
      v-if="message"
      class="email-detail-head p-1"
    >
      <div class="user-details d-flex justify-content-between align-items-center flex-wrap">
        <div v-if="message.user" class="mail-items">
          <h5 class="mb-0">
            {{ message.user.name }}
          </h5>
        </div>
        <div v-else class="mail-items">
          <h5 class="mb-0">
            SYSTEM MESSAGE
          </h5>
        </div>
      </div>
      <div class="mail-meta-item d-flex align-items-center">
        <small class="mail-date-time text-muted"> {{ message.created_at | formatDateTime }} </small>
      </div>
    </b-card-header>

    <!-- Message -->
    <b-card-body class="mail-message-wrapper p-1">
      <!-- eslint-disable vue/no-v-html -->
      <p class="mail-message m-0" style="white-space: pre-wrap; ">{{ message.message }}</p>
      <!-- eslint-enable -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
   BCard, BCardHeader, BCardBody,
} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      formatDate,
    }
  },
}
</script>

<style>

</style>
