import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/tasks';

export default {
  listTasks(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },

  getTask(taskId) {
    return axios.get(`${baseUrl}/${taskId}`)
  },

  updateTask(id, task) {
    return axios.put(`${baseUrl}/${id}`, task);
  },
}
