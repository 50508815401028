import { render, staticRenderFns } from "./ViewTasks.vue?vue&type=template&id=7af1fdf4&scoped=true"
import script from "./ViewTasks.vue?vue&type=script&lang=js"
export * from "./ViewTasks.vue?vue&type=script&lang=js"
import style1 from "./ViewTasks.vue?vue&type=style&index=1&id=7af1fdf4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7af1fdf4",
  null
  
)

export default component.exports