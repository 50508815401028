import axios from '@/libs/axios';
import HelperService from '@/services/HelperService';

const baseUrl = '/tasks';

export default {
  list(id, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${id}/messages${queryString}`);
  },
  create(taskId, data) {
    return axios.post(`${baseUrl}/${taskId}/messages`, data)
  },
}
