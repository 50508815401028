<template>
  <b-modal
    id="compose-mail"
    :visible="shallShowTaskMessageComposeModal"
    title="Compose Task Message"
    modal-class="modal-sticky"
    footer-class="d-flex justify-content-between"
    body-class="p-0"
    size="sm"
    no-fade
    hide-backdrop
    static
    @change="(val) => $emit('update:shall-show-email-compose-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        Compose Message
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="$emit('update:shall-show-email-compose-modal', false)"
        />
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="discardEmail"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          variant="primary"
          :disabled="composeData.length === 0"
          @click="createTaskMessage('Note')"
        >
          Post
        </b-button>
      </div>
    </template>

    <!-- Modal: Body -->
    <b-form>
      <!-- Field: Message - Quill Editor -->
      <div class="message-editor">
        <b-input-group>
          <b-form-textarea v-model="composeData" placeholder="Enter Message...." class="rounded-0" rows="5" no-resize />
        </b-input-group>
      </div>
    </b-form>

  </b-modal>
</template>

<script>
import {
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import taskMessageService from '@/services/TaskMessageService';
import helperService from '@/services/HelperService';
import { useToast } from 'vue-toastification/composition';

export default {
  directives: {
    Ripple,
  },
  components: {

    // BS
    BForm,
  },
  model: {
    prop: 'shallShowTaskMessageComposeModal',
    event: 'update:shall-show-email-compose-modal',
  },
  props: {
    shallShowTaskMessageComposeModal: {
      type: Boolean,
      required: true,
    },
    taskId: {
      type: Number || String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const toast = useToast()
    const composeData = ref('')
    const createTaskMessage = async messageType => {
      try {
        const data = {
          message: composeData.value,
          type: messageType,
        }
        await taskMessageService.create(props.taskId, data)
        helperService.showNotfySuccess(toast, 'Message created successfully')
        composeData.value = ''
        emit('task-message-created')
        emit('update:shall-show-email-compose-modal', false)
      } catch (err) {
        const res = err.response
        let errorText = 'Could not create task message, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        helperService.showNotfyErr(toast, err, errorText)
      }
    }

    watch(() => props.taskId, () => {
      composeData.value = ''
    });

    const discardEmail = () => {
      composeData.value = ''
      emit('update:shall-show-email-compose-modal', false)
    }

    return {
      composeData,

      createTaskMessage,
      discardEmail,
    }
  },
}
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
form ::v-deep {

  // Mail To vue-select style
  .v-select {
    .vs__dropdown-toggle {
      border: 0;
      box-shadow: none;
    }
    .vs__open-indicator {
      display: none;
    }
  }
}
</style>
