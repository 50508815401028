<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="orderData === null">
      <h4 class="alert-heading">
        Error fetching order data
      </h4>
      <div class="alert-body">
        No order found with this order id. Has it been deleted?
      </div>
    </b-alert>

    <b-row v-if="orderData" class="invoice-preview">

      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <h3 class="text-primary ">
                    Order # {{ orderData.uid }}
                  </h3>
                </div>
                <p class="card-text mb-25">
                  Owner: {{ orderData.owner.additional_info }} ({{ orderData.owner.identifier }})
                </p>
                <table>
                  <tbody>
                    <tr>
                      <td class="pr-1">
                        Shipping:
                      </td>
                      <td>£{{ Number(orderData.shipping_cost/100).toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Total Cost:
                      </td>
                      <td>
                        <span class="font-weight-bold">
                          £{{ Number(orderData.total_cost/100).toFixed(2) }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td class="pr-1">
                        Status:
                      </td>
                      <td :class="'text-' + resolveOrderStatusColour(orderData.status)"> <strong>{{ orderData.status }}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Ordered On
                  <span class="invoice-number">
                    {{ orderData.created_at | formatDateTime }}
                  </span>
                </h4>
                <div v-if="orderData.status === 'Pending' && $can('Update','Order')">

                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-75 mr-1" @click="authoriseOrderModal.show()">
                    Authorise
                  </b-button>

                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mb-75" @click="rejectOrderModal.show()">
                    Reject
                  </b-button>

                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite responsive :items="orderData.order_rows" :fields="orderRowFields">
            <template #cell(price)="data">
              <b-card-text class="font-weight-bold mb-25">
                £{{ Number(data.item.price/100).toFixed(2) }}
              </b-card-text>
            </template>
            <template #cell(name)="data">
              <b-card-text class="font-weight-bold mb-25">
                <a v-if="data.item.url" :href="data.item.url" target="_blank">{{ data.item.name }}</a>
                <span v-else>{{ data.item.name }}</span>
              </b-card-text>
              <b-card-text class="text-nowrap">
                Code: {{ data.item.code }}
                <span v-if="data.item.size !== ''">
                  (Size: {{ data.item.size }})
                </span>
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-2">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              />

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Sub-Total:
                    </p>
                    <p class="invoice-total-amount">
                      £{{ Number((orderData.total_cost - orderData.shipping_cost)/100).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Shipping:
                    </p>
                    <p class="invoice-total-amount">
                      £{{ Number(orderData.shipping_cost/100).toFixed(2) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      £{{ Number(orderData.total_cost/100).toFixed(2) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

    </b-row>
    <b-modal ref="authoriseOrderModal" title="" hide-footer>
      <warning-modal title="Are you sure you wish to authorise this order?" ok-variant="success" submit-btn-text="Authorise"
                     @ok="authoriseOrder"
                     @close="closeModals()"
      />
    </b-modal>

    <b-modal ref="rejectOrderModal" title="" hide-footer>
      <warning-modal title="Are you sure you wish to reject this order?"
                     note="Rejecting this order will refund the owner"
                     submit-btn-text="Reject"
                     @ok="rejectOrder"
                     @close="closeModals()"
      />
    </b-modal>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import OrderService from '@/services/OrderService';
import helperService from '@/services/HelperService';
import { useToast } from 'vue-toastification/composition';
import WarningModal from '@/components/modals/WarningModal.vue';
import { onMounted, ref } from '@vue/composition-api';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    WarningModal,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
  },
  props: {
    orderData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const toast = useToast()

    const orderRowFields = [
      {
        key: 'name',
        label: 'name',
        thClass: 'text-left',
        tdClass: 'text-left',
      },
      {
        key: 'quantity',
        label: 'Qty',
        thClass: 'text-left',
        tdClass: 'text-left',
      },
      {
        key: 'price',
        label: 'price',
        thClass: 'text-right',
        tdClass: 'text-right',
      },
    ]

    const idempotencyKey = ref('')

    // modals
    const authoriseOrderModal = ref(null)
    const rejectOrderModal = ref(null)

    const closeModals = () => {
      authoriseOrderModal.value.hide()
      rejectOrderModal.value.hide()
    }

    const generateIdempotencyKey = () => {
      const keyLength = 20
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = ' ';
      const charactersLength = characters.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < keyLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      idempotencyKey.value = result
    }

    onMounted(() => {
      generateIdempotencyKey()
    })

    const authoriseOrder = async () => {
      try {
        await OrderService.authorise(props.orderData.uid)
        helperService.showNotfySuccess(toast, 'Order has been authorised')
        closeModals()
        emit('order-updated')
      } catch (err) {
        const res = err.response
        let errorText = 'Could not authorise order, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        helperService.showNotfyErr(toast, err, errorText)
      }
    }

    const rejectOrder = async () => {
      try {
        await OrderService.reject(props.orderData.uid, {
          idempotency_key: idempotencyKey.value,
        })
        helperService.showNotfySuccess(toast, 'Order has been rejected')
        closeModals()
        emit('order-updated')

        // generate new key
        generateIdempotencyKey()
      } catch (err) {
        const res = err.response
        let errorText = 'Could not reject order, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        helperService.showNotfyErr(toast, err, errorText)
      }
    }

    const resolveOrderStatusColour = status => {
      switch (status) {
        case 'Pending':
          return 'warning'
        case 'Accepted':
          return 'success'
        case 'Rejected':
          return 'danger'
        default:
          return 'primary'
      }
    }

    return {
      orderRowFields,
      authoriseOrder,
      rejectOrder,
      closeModals,
      resolveOrderStatusColour,

      // Modals
      authoriseOrderModal,
      rejectOrderModal,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
