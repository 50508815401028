<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >

            <h6 class="section-label mb-1 mt-2 px-2">
              Categories
            </h6>
            <!-- Filters -->
            <b-list-group class="list-group-messages mt-2" style="cursor: pointer">
              <b-list-group-item
                v-for="filter in taskCategoryFilters"
                v-show="$can(filter.action, filter.resource)"
                :key="filter.title"
                :active="isActiveCategoryFilter(filter.value)"
                @click="updateCategoriesFilter(filter.value)"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <h6 class="section-label mb-1 mt-2 px-2">
              Filters
            </h6>

            <b-list-group class="list-group-labels">
              <!-- Assigned user filter (Current User) --->
              <b-list-group-item
                :active="isActiveAssignedFilter"
                style="cursor: pointer"
                @click="updateAssignedFilter"
              >
                <feather-icon
                  icon="UserIcon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">Assigned to you</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Status Filter -->
            <h6 class="section-label mb-1 mt-2 px-2">
              Status
            </h6>

            <b-list-group class="list-group-labels" style="cursor: pointer">
              <b-list-group-item
                v-for="status in taskStatusFilter"
                :key="status.title"
                :active="isActiveStatusFilter(status.title)"
                @click="updateStatusFilter(status.title)"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${status.color}`"
                />
                <span>{{ status.title }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api';

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BListGroup,
    BListGroupItem,

    // 3rd Party
    VuePerfectScrollbar,
  },
  setup(props, { emit }) {
    // -------- Variables-------
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const statusesFilter = ref(['Open', 'Ongoing'])
    const categoriesFilter = ref('')
    const isActiveAssignedFilter = ref(false)

    // -------Valid Task Categories-------
    const taskCategoryFilters = [
      {
       title: 'All', icon: 'ListIcon', value: '', resource: 'Tracker', action: 'Read',
      },
            {
       title: 'Shopping', icon: 'ShoppingCartIcon', value: 'Shopping', resource: 'Tracker.Shopping', action: 'Read',
      },
            {
       title: 'Security', icon: 'LockIcon', value: 'Security', resource: 'Tracker.Security', action: 'Read',
      },
      {
        title: 'Issues', icon: 'AlertCircleIcon', value: 'Issue', resource: 'Tracker.Issue', action: 'Read',
      },
      {
        title: 'Stores', icon: 'ArchiveIcon', value: 'Store', resource: 'Tracker.Store', action: 'Read',
      },
    ]
    // -------Valid Task Statuses-------
    const taskStatusFilter = [
      { title: 'Open', color: 'success' },
      { title: 'Ongoing', color: 'primary' },
      { title: 'Closed', color: 'danger' },
    ]

    // ------- Methods -------

    const updateStatusFilter = status => {
      // If clicked status is selected, remove it from the list
      // else add it to the list of selected
      const index = statusesFilter.value.indexOf(status)
      if (index > -1) {
        statusesFilter.value.splice(index, 1)
      } else {
        statusesFilter.value.push(status)
      }
      // Trigger a refresh on ViewTasks page
      emit('update-status-filter', statusesFilter.value.toString())
    }

    const updateCategoriesFilter = category => {
      categoriesFilter.value = category
      emit('update-category-filter', category)
    }

    const updateAssignedFilter = () => {
      isActiveAssignedFilter.value = !isActiveAssignedFilter.value
      emit('update-assigned-filter')
    }

    const isActiveStatusFilter = status => statusesFilter.value.indexOf(status) > -1
    const isActiveCategoryFilter = category => category === categoriesFilter.value

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,

      // Filter & Labels
      taskCategoryFilters,
      taskStatusFilter,
      updateStatusFilter,
      updateCategoriesFilter,
      updateAssignedFilter,
      isActiveStatusFilter,
      isActiveCategoryFilter,
      isActiveAssignedFilter,
    }
  },
}
</script>

<style>

</style>
