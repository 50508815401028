<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="issueData === null">
      <h4 class="alert-heading">
        Error fetching issue data
      </h4>
      <div class="alert-body">
        No issue found with this issue id. Has it been deleted?
      </div>
    </b-alert>

    <b-row v-if="issueData" class="issue-preview">

      <!-- Issue Container -->
      <b-col cols="12">
        <b-card no-body class="issue-preview-card pb-1">

          <!-- Header -->
          <b-card-body class="issue-padding pb-0">
            <div class="d-flex justify-content-between issue-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper mb-3">
                  <h3 class="text-primary mb-25">
                    Issue # {{ issueData.id }}
                  </h3>
                  <span>
                    <strong class="d-inline-block mr-25">
                      {{ issueName }} Issue.
                    </strong>
                    <small v-if="issueData.model_uid" class="d-inline-block">
                      <router-link :to="{ name: viewRouteName, params: { id: issueData.model_uid } }" class="font-weight-bold text-nowrap">
                        View {{ issueName }}.
                      </router-link>
                    </small>
                  </span>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 text-right">
                <h4 class="mb-25">
                  <strong>Reported On:</strong> <span>{{ issueData.created_at | formatDateTime }}</span>
                </h4>
                <p>
                  <strong>Severity:</strong> <span :class="'text-' + resolveSeverityColour(issueData.severity)">{{ issueData.severity }}</span>
                </p>
              </div>
            </div>

            <!-- Body: Issue Details -->
            <div>
              <p class="mb-25">
                <strong>Subject:</strong> {{ issueData.subject }}
              </p>
              <p class="mb-25">
                <strong>Description:</strong> {{ issueData.description }}
              </p>
            </div>
            <div v-if="issueData.errors" class="card-text mb-25">
              <strong>Errors:</strong> {{ issueData.errors }}
            </div>
            <div v-if="issueData.reproduction" class="card-text mb-25">
              <strong>Reproduction Steps:</strong> {{ issueData.reproduction }}
            </div>
          </b-card-body>

        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BAlert,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAlert,
  },
  props: {
    issueData: {
      type: Object, // or the appropriate type
      required: true,
    }
  },
  computed: {
    issueName() {
      switch (this.issueData.model) {
        case 'machines':
          return 'Machine';
        default:
          return 'General';
      }
    },
    viewRouteName() {
      return `${this.issueName.toLowerCase()}-view`;
    }
  },
  methods: {
    resolveSeverityColour(severity) {
      switch (severity) {
        case 'CRITICAL':
          return 'danger';
        case 'HIGH':
          return 'warning';
        case 'MEDIUM':
          return 'primary';
        case 'LOW':
          return 'success';
        default:
          return '';
      }
    }
  }
}
</script>

<style>

</style>
