<template>
  <div>
    <p v-if="loading">
      Loading...
    </p>
    <div v-else>
      <p
        v-if="!location.apply_volumetric_controls"
        class="ml-2 text-danger"
      >
        Volumetric controls are disabled for this location.
      </p>
      <p v-if="volumetricControls.length === 0" class="m-2">
        No Data Found for this location
      </p>
      <b-card-group v-else deck>
        <b-card
          v-for="(control, index) in volumetricControls"
          :key="index"
          no-body
        >
          <template #header>
            <h4>
              <b>
                {{ control.category_name }}
                <span v-if="control.max_items >= 0"> - {{ control.current_items }}/{{ control.max_items }}</span>
                <span v-else> - No Limit</span>
              </b>
            </h4>
          </template>
          <b-list-group flush>
            <b-list-group-item
              v-for="(sub_control,subIndex) in control.subcategories"
              :key="`sub-${subIndex}`"
            >
              {{ sub_control.category_name }}
              <span v-if="sub_control.max_items >= 0"> - {{ sub_control.current_items }}/{{ sub_control.max_items }}</span>
              <span v-else> - No Limit</span>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-card-group>
    </div>
  </div>
</template>

<script>
import VolumetricControlsService from "@/services/VolumetricControlsService";

export default {
  name: "VolumetricControl",
  props: {
    ownerUid: {
      type: String,
      required: true,
    },
    locationId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      loading: true,
      location: null,
      volumetricControls: [],
    }
  },
  watch: {
    locationId: function() {
      this.getVolumetricControls();
    },
  },
  mounted() {
    this.getVolumetricControls()
  },
  methods: {
    async getVolumetricControls() {
      try {
        const res = await VolumetricControlsService.get(this.ownerUid, this.locationId)
        this.location = res.data.location
        this.volumetricControls = res.data.controls;
      } catch(err) {
        const res = err.response
        let errorText = 'Could not get volumetric controls for this location, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>
