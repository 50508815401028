export default function useTask() {
  const resolveStatusColor = status => {
    if (status === 'Open') return 'success'
    if (status === 'Ongoing') return 'primary'
    if (status === 'Closed') return 'danger'
    return 'secondary'
  }

  const resolveCategoryIcon = category => {
    if (category === 'Shopping') return 'ShoppingCartIcon'
    if (category === 'Security') return 'LockIcon'
    return 'secondary'
  }

  return {
    resolveStatusColor,
    resolveCategoryIcon,
  }
}
