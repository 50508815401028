<template>
  <div>
    <div class="p-0 d-flex justify-content-between mb-2">
      <b-card-title class="my-auto m-0">Messages</b-card-title>
      <div v-if="$can('Create', 'TrackerMessage')" class="form-group-compose compose-btn">
        <b-button
          variant="primary"
          @click="shallShowTaskMessageComposeModal = true; $emit('close-left-sidebar')"
        >
          Create message
        </b-button>
      </div>
    </div>

    <div>
      <task-message-card v-for="message in taskMessages" :key="message.id" :message="message" />
      <b-pagination v-if="totalCount > filters.page_size" v-model="currentPage" :total-rows="totalCount" :per-page="filters.page_size" class="justify-content-center mb-5" @change="getMoreTaskMessages" />
    </div>

    <b-card v-if="taskMessages.length === 0">
      <h6 class="text-center m-0">This task has no messages.
        <a v-if="$can('Create', 'TrackerMessage')" class="primary" @click="shallShowTaskMessageComposeModal = true; $emit('close-left-sidebar')">Click here to create one</a>
      </h6>
    </b-card>
    <task-message-compose v-model="shallShowTaskMessageComposeModal" :task-id="taskId" @task-message-created="getTaskMessages" />
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api';
import helperService from '@/services/HelperService';
import { useToast } from 'vue-toastification/composition';
import TaskMessageService from '@/services/TaskMessageService';
import TaskMessageCard from '@/views/tracker/TaskMessageCard.vue';
import TaskMessageCompose from '@/views/tracker/TaskMessageCompose.vue';

export default {
  name: 'ViewTaskMessages',
  components: {
    TaskMessageCard,
    TaskMessageCompose,
  },
  props: {
    taskId: {
      type: Number || String,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast()
    const taskMessages = ref([])
    const shallShowTaskMessageComposeModal = ref(false)
    const filters = ref({
      page_size: 5,
      page: 0,
    })
    const totalCount = ref(0)
    const currentPage = ref(1)
    const getTaskMessages = async () => {
      try {
        const res = await TaskMessageService.list(props.taskId, filters.value)
        taskMessages.value = res.data.data
        filters.value.page = res.data.meta.page
        filters.value.page_size = res.data.meta.page_size
        totalCount.value = res.data.meta.total_count
      } catch (err) {
        const res = err.response
        let errorText = 'Could get task messages, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        helperService.showNotfyErr(toast, err, errorText)
      }
    }

    const getMoreTaskMessages = val => {
      filters.value.page = val - 1;
      getTaskMessages()
    }

    getTaskMessages()

    watch(() => props.taskId, () => {
     getTaskMessages()
    })

    return {
      taskMessages,
      shallShowTaskMessageComposeModal,
      getTaskMessages,
      getMoreTaskMessages,
      filters,
      totalCount,
      currentPage,
    }
  },
}
</script>

<style scoped>

</style>
