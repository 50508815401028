<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />

    <!-- Task List -->
    <div class="email-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon
            icon="MenuIcon"
            size="21"
            class="cursor-pointer"
            @click="mqShallShowLeftSidebar = true"
          />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search tasks by Title or Description"
              @input="getMoreTasks(1)"
            />
          </b-input-group>
        </div>
      </div>

      <!-- App Action Bar -->
      <div class="app-action p-1">
        <div class="action-left">
          <b-form-checkbox
            :checked="selectAllTasksCheckbox"
            :indeterminate="isSelectAllTasksCheckboxIndeterminate"
            @change="selectAllCheckboxUpdate"
          >
            Select All
          </b-form-checkbox>
        </div>
      </div>

      <!-- Tasks List -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="email-user-list scroll-area"
      >
        <ul class="email-media-list">
          <b-media
            v-for="task in tasks"
            :key="task.id"
            tag="li"
            no-body
            class="p-1"
            @click="updateTaskViewData(task.id)"
          >

            <b-media-aside class="media-left mr-50">
              <div class="user-action">
                <b-form-checkbox
                  :checked="selectedTasks.includes(task.id)"
                  @change="toggleSelectedTask(task.id)"
                  @click.native.stop
                />
                <div class="email-favorite">
                  <feather-icon
                    icon="StarIcon"
                    size="17"
                  />
                </div>
              </div>
            </b-media-aside>

            <b-media-body>
              <div class="mail-details m-0">
                <div class="d-flex justify-content-start">
                  <feather-icon
                    :icon="`${resolveCategoryIcon(task.category)}`"
                    size="17"
                  />
                  <span class="col">{{ task.title }}</span>
                </div>
                <div :id="`task-info-${task.id}`">
                  <span v-if="task.user"> {{ task.user.name }}</span>
                  <span v-else>Not Assigned</span>
                  <span
                    class="mx-50 bullet bullet-sm"
                    :class="`bullet-${resolveStatusColor(task.status)}`"
                  />
                  <b-badge
                    v-if="task.due_date"
                    pill
                    class="text-capitalize"
                    :variant="resolveDueDateColor(task.due_date)"
                  > Due: {{ task.due_date | formatDate }} </b-badge>

                  <b-tooltip
                    :target="`task-info-${task.id}`"
                    placement="top"
                  >
                    <p class="mb-0 bold text-left">
                      Status: {{ task.status }}
                    </p>
                    <p class="mb-0 bold text-left">
                      Created on: {{ task.created_at | formatDate }}
                    </p>
                  </b-tooltip>
                </div>
              </div>
            </b-media-body>
          </b-media>
        </ul>

        <b-pagination v-if="totalCount > filters.page_size" v-model="currentPage" :total-rows="totalCount" :per-page="filters.page_size" class="justify-content-center my-2" @change="getMoreTasks" />
        <div
          class="no-results"
          :class="{'show': totalCount === 0}"
        >
          <h5>No Items Found</h5>
        </div>
      </vue-perfect-scrollbar>
    </div>

    <!-- Task View/Detail -->
    <task-view
      v-if="taskViewData"
      :class="{'show': showTaskDetails}"
      :task-id="taskViewData"
      @close-task-view="closeTaskView"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <tracker-left-sidebar
        :class="{'show': mqShallShowLeftSidebar }"
        @update-category-filter="updateCategoryFilter"
        @update-status-filter="updateStatusFilter"
        @update-assigned-filter="updateAssignedFilter"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
      />
    </portal>

  </div>
</template>

<script>
import store from '@/store'
import {
  computed, onMounted, ref, watch,
} from '@vue/composition-api'
import {
  BFormCheckbox,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BMedia,
  BMediaAside,
  BMediaBody,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { filterTags, formatDateToMonthShort } from '@core/utils/filter'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import TaskService from '@/services/TaskService';
import helperService from '@/services/HelperService';
import { useToast } from 'vue-toastification/composition';
import { useRouter } from '@core/utils/utils';
import TrackerLeftSidebar from './TrackerLeftSidebar.vue'
import TaskView from './TaskView.vue'
import useTask from './useTask'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    BMedia,
    BMediaAside,
    BMediaBody,

    // 3rd Party
    VuePerfectScrollbar,

    // App SFC
    TrackerLeftSidebar,
    TaskView,
  },
  setup() {
    const { resolveStatusColor, resolveCategoryIcon } = useTask()
    const toast = useToast()
    const { route, router } = useRouter()

    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // Tasks & pagination variables
    const tasks = ref([])
    const selectedTasks = ref([])
    const totalCount = ref(0)
    const currentPage = ref(1)
    const filters = ref({
      page: 0,
      page_size: 12,
      search: '',
      category: '',
      status: 'Open,Ongoing', // Set Default status filter
      user_uid: '',
    })

    const showTaskDetails = ref(false)
    const taskViewData = ref(null)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // Computed Variables
    const selectAllTasksCheckbox = computed(() => tasks.value.length && (tasks.value.length === selectedTasks.value.length))
    const isSelectAllTasksCheckboxIndeterminate = computed(() => Boolean(selectedTasks.value.length) && tasks.value.length !== selectedTasks.value.length)

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const toggleSelectedTask = taskId => {
      const taskIndex = selectedTasks.value.indexOf(taskId)

      if (taskIndex === -1) selectedTasks.value.push(taskId)
      else selectedTasks.value.splice(taskIndex, 1)
    }
    const selectAllCheckboxUpdate = val => {
      selectedTasks.value = val ? tasks.value.map(task => task.id) : []
    }

    const getTasks = async () => {
      try {
        const res = await TaskService.listTasks(filters.value)
        tasks.value = res.data.data
        filters.value.page = res.data.meta.page
        filters.value.page_size = res.data.meta.page_size
        totalCount.value = res.data.meta.total_count

        // Check if task Id is in route
        if (route.value.params.taskId) {
          taskViewData.value = route.value.params.taskId
          showTaskDetails.value = true
        }
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get tasks, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }
        helperService.showNotfyErr(toast, err, errorText)
      }
    }

    const getMoreTasks = val => {
      currentPage.value = val
      filters.value.page = val - 1;
      getTasks()
    }
    const updateTaskViewData = id => {
      taskViewData.value = id
      showTaskDetails.value = true
      // add task id into route without refresh
      router.replace({ name: route.value.name, params: { taskId: id } })
    }
    // ------------------Update Filters-----------------
    const updateCategoryFilter = category => {
      filters.value.category = category
      getMoreTasks(1)
    }
    const updateStatusFilter = status => {
      filters.value.status = status
      getMoreTasks(1)
    }

    const updateAssignedFilter = () => {
      // set the assigned filter to the current users uid if not already, else clear the filter
      if (filters.value.user_uid === '') {
        filters.value.user_uid = store.state.user.currentUser.uid
      } else {
        filters.value.user_uid = ''
      }
      getMoreTasks(1)
    }

    const closeTaskView = () => {
      router.replace({ name: route.value.name, params: {} })
      showTaskDetails.value = false;
      getTasks()
    }

    // Set colour to 'danger' if due date is past
    const resolveDueDateColor = date => {
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      const dueDate = new Date(date)

      if (dueDate >= currentDate) {
        return 'primary'
      }
      return 'danger'
    }

    // If someone clicks on a filter while viewing detail => Close the task detail view
    watch(filters.value, () => {
      // clear route param if one is set
      if (route.value.params.taskId) {
        router.replace({ name: route.value.name, params: {} })
      }
      showTaskDetails.value = false;
    })

    onMounted(() => {
      getTasks()
    })

    return {
      // UI
      perfectScrollbarSettings,

      // Task Selection
      selectAllTasksCheckbox,
      isSelectAllTasksCheckboxIndeterminate,
      selectedTasks,
      toggleSelectedTask,
      selectAllCheckboxUpdate,

      // Task Details
      showTaskDetails,
      taskViewData,
      updateTaskViewData,

      // UI Filters
      filterTags,
      updateStatusFilter,
      updateCategoryFilter,
      updateAssignedFilter,
      formatDateToMonthShort,

      // get status colours, category icon and due date colour
      resolveStatusColor,
      resolveCategoryIcon,
      resolveDueDateColor,

      // Left Sidebar Responsiveness
      mqShallShowLeftSidebar,

      // tasks methods
      getTasks,
      getMoreTasks,
      closeTaskView,

      // variables
      filters,
      totalCount,
      currentPage,
      tasks,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";
</style>
