import axios from '@axios';

const baseUrl = '/orders';

export default {
  get(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  authorise(uid) {
    return axios.put(`${baseUrl}/${uid}/authorise`)
  },
  reject(uid, idempotencyKey) {
    return axios.put(`${baseUrl}/${uid}/reject`, idempotencyKey)
  },
};
